// Since we are using turbolink, we must use on('turbolinks:load') instead of ready()
$(document).ready(function() {
  // enable bootstrap tooltip
  $("[data-toggle='tooltip']").tooltip();
  // enable bootstrap popovers everywhere
  $('[data-toggle="popover"]').popover({
    trigger: 'hover',
    html: true,
    delay: { "show": 100, "hide": 2000 }
  });
  // TODO: create a generic function for this
  $('#bank-panel-invitation-file-field').change(function(){
    validateFiles(this);
  });

  // Fix for custom input file on bootswatch
  $('.custom-file-input').change(function(){
    files_count = $(this).prop('files').length
    if( files_count > 1){
      $(this).parent().find('.custom-file-label').html(files_count + ' arquivos selecionados');
    } else {
      let filename = $(this).val().split("\\").slice(-1).pop();
      $(this).parent().find('.custom-file-label').html(filename);
    }
  });
  $('.custom-file-submit').click(function(){
    if($('.custom-file-input').value !== "") {
      $(this).addClass('d-none');
      $('.spinner-button').removeClass('d-none');
    }
  });

  $(".cnpj").mask("00.000.000/0000-00", { reverse: true });
  $(".phone_number").mask(SPMaskBehavior, spOptions);
  $(".copy_to_clipboard").click(function(e){
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(this).attr('href')).select();
    document.execCommand("copy");
    toastr.success($(this).attr('data-message'));
    $(".dropdown-menu").removeClass('show');
    $temp.remove();
    e.preventDefault();
    return false;
  });
  $('.click-on-page-load').each(function(){
    this.click();
  });
});

function validateFiles(inputFile) {
  var maxExceededMessage = "This file exceeds the maximum allowed file size (5 MB)";
  var extErrorMessage = "Apenas arquivos com as extensões: .xls, .xlsx e .xlsm são permitidos";
  var allowedExtension = ["xls", "xlsx", "xlsm"];

  var extName;
  var maxFileSize = $(inputFile).data('max-file-size');
  var sizeExceeded = false;
  var extError = false;

  $.each(inputFile.files, function() {
    if (this.size && maxFileSize && this.size > parseInt(maxFileSize)) {sizeExceeded=true;}
    extName = this.name.split('.').pop();
    if ($.inArray(extName, allowedExtension) == -1) {extError=true;}
  });
  if (sizeExceeded) {
    window.alert(maxExceededMessage);
    $(inputFile).val('');
  }

  if (extError) {
    window.alert(extErrorMessage);
    $(inputFile).val('');
  }
}

var SPMaskBehavior = function (val) {
  return val.replace(/\D/g, '').length === 11 ? "(00) 00000-0000" : "(00) 0000-00009";
},
spOptions = {
  onKeyPress: function(val, e, field, options) {
      field.mask(SPMaskBehavior.apply({}, arguments), options);
    }
};
